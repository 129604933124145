import Image from 'next/image';
import { Button } from '@nextui-org/react';
import React from 'react';
import { BackgroundMask } from '../commons/BackgroundMask';
import CTAButton from '../commons/CTAButton';
import Amity from '@/assets/images/amity.png';
import JECRC from '@/assets/images/jecrc.png';
import Mukand from '@/assets/images/mukand.png';
import JKLU from '@/assets/images/jklu.png';
import Manipal from '@/assets/images/manipal.png';
import Piet from '@/assets/images/piet.png';
import NSUT from '@/assets/images/netaji-university.png'
import SRM from '@/assets/images/SRM-university.png'
import AmityMumbai from '@/assets/images/amity-mumbai-university.png'

const OurPartners = () => {
  const partners1 = [Amity, JECRC, Mukand, JKLU, Manipal, Piet,NSUT,SRM,AmityMumbai];
  const partners2 = [Amity, JECRC, Mukand, JKLU, Manipal, Piet,NSUT,SRM,AmityMumbai];

  return (
    <div
      className="bg-gradient-to-r from-[#ccffe5] to-[#fff5e5] md:py-[60px] py-4 text-center"
      style={{
        background:
          'linear-gradient(90deg, #E2FFF4 0%, #EBFBF3 49.77%, #FFF5F2 100%)',
      }}
    >
      {/* Title */}
      <div className="md:space-y-4 md:mb-12 mb-6">
        <h2 className="md:text-[40px] font-medium text-lg font-sans md:mb-4">
          Our Partners
        </h2>
        <p className="md:text-xl font-sans text-[#4F4F4F] md:tracking-wide tracking-wide text-sm font-normal md:mb-12 mb-6">
          Building a Future of Excellence Together with Our Partners!{' '}
        </p>
      </div>

      {/* Scrolling Cards Container */}
      <div className="relative overflow-hidden max-w-[15rem] md:max-w-5xl mx-auto group">
        {/* Scrolling Container 1 */}
        <div className="flex gap-2 md:gap-4 animate-scroll hover:pause ">
          {[...partners1, ...partners1].map((partner, index) => (
            <Image
              key={index}
              className="flex-shrink-0 w-[50px] h-[50px] md:min-w-[150px] md:min-h-[110px] shadow-none rounded-lg md:rounded-2xl flex items-center object-fill justify-center"
              src={partner}
              alt={partner}
            />
          ))}
        </div>
      </div>

      <div className="relative overflow-hidden max-w-[13rem] md:max-w-3xl mx-auto">
        {/* Scrolling Container 2 (opposite direction) */}
        <div className="flex gap-2 md:gap-4 animate-scroll-reverse hover:pause mt-8">
          {[...partners2, ...partners2].map((partner, index) => (
            <Image
              key={index}
              className="flex-shrink-0 w-[50px] h-[50px] md:min-w-[150px] md:min-h-[110px] shadow-none rounded-lg md:rounded-2xl flex items-center object-fill justify-center"
              src={partner}
              alt={partner}
            />
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="md:space-y-6 space-y-1 md:mt-16 mt-8">
        <p className="md:text-4xl font-sans font-medium ">
          Ready to Collaborate?
        </p>
        <p className="md:text-4xl font-sans font-medium ">
          <span className="text-green-600">Partner with Us</span> for Success!
        </p>

        {/* Web Button */}
        <div className="flex justify-center">
          <CTAButton className="hidden md:block box-content px-14 ">
            Partner with Us
          </CTAButton>

          <CTAButton size="sm" className="md:hidden text-center">
            Partner with Us
          </CTAButton>
        </div>
      </div>
    </div>
  );
};

export default OurPartners;

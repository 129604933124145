import React, { useEffect, useState } from 'react';
// Import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image'; // Assuming you're using Next.js's Image component
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';
import { Card, CardHeader, CardFooter, CardBody } from '@nextui-org/react';
import { BackgroundMask } from '../commons/BackgroundMask';
import user1 from '@/assets/testimonials/one.jpg';
import user2 from '@/assets/testimonials/two.jpg';
import user3 from '@/assets/testimonials/three.jpg';
import user4 from '@/assets/testimonials/four.jpg';
import user5 from '@/assets/testimonials/five.jpg';
import user6 from '@/assets/testimonials/five.jpg';

// const testimonial_images = [user1, user2, user3, user4, user5, user6];

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const OurCommunity = () => {
  // const events = [1, 2, 3, 4, 5, 6, 7];

  const [isresponsive, setIsResponsive] = useState('vertical');

  useEffect(() => {
    const handleResize = () => {
      const newOrientation =
        window.innerWidth >= 768 ? 'horizontal' : 'vertical';
      setIsResponsive(newOrientation);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const testimonials = [
    {
      id: 1,
      name: 'Ashish Jain',
      message:
        'Hackingly organizes exceptional, professional-level hackathons that inspire innovation and foster skill development. With expert guidance, seamless coordination, and a focus on real-world challenges, they create transformative experiences for participants. Highly recommended!',
      image: user1,
    },
    {
      id: 2,
      name: 'Rohit Pawar',
      message:
        'Hackingly excels at organizing insightful and highly engaging sessions. Their focus on delivering practical knowledge, industry-relevant insights, and expert guidance makes every session a valuable learning experience. Perfect for anyone looking to upskill and stay ahead in their field!',
      image: user2,
    },
    {
      id: 3,
      name: 'Ajay Singh',
      message:
        'Hackingly organizes highly engaging and insightful sessions that provide practical knowledge and industry-relevant insights. Perfect for staying updated and gaining skills for real-world success!',
      image: user6,
    },
    {
      id: 4,
      name: 'Chavi Rawat',
      message:
        'Hackingly is a game-changer for anyone seeking career growth. Their platform not only enhances skills but also connects participants with incredible internship and job opportunities. By bridging the gap between talent and industry, Hackingly empowers individuals to launch and advance their careers with confidence. Highly recommended!',
      image: user4,
    },
    {
      id: 5,
      name: 'Vihaan',
      message:
        'Hackingly provides exceptional career opportunities by connecting participants with top internships and jobs while enhancing their skills. A trusted platform for launching and advancing your career!',
      image: user5,
    },
    {
      id: 6,
      name: 'Disha',
      message:
        'Hackingly organizes top-tier, professional hackathons that foster innovation and collaboration. With expert mentorship and world-class resources, they provide an invaluable platform for showcasing talent and tackling real-world challenges.',
      image: user3,
    },
  ];

  return (
    <BackgroundMask>
      <div className="bg-white py-[60px] layoutBox">
        <div className=" ">
          <h2 className="md:text-[40px] text-lg font-medium text-center">
            Real Stories, Real Impact: What Our Community Says{' '}
          </h2>
          <p className="md:pt-4 md:text-xl md:tracking-wide tracking-wide text-sm font-sans text-[#4F4F4F] text-center">
            Stories Of Growth And Success
          </p>

          {/* Swiper carousel */}

          {isresponsive === 'horizontal' ? (
            <div>
              {/* for web */}
              <Swiper
                className="relative mx-6"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={4}
                navigation
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 3,
                  },
                  620: {
                    slidesPerView: 2,
                    spaceBetween: 3,
                  },
                  1000: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1410: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {testimonials.map(item => (
                  <SwiperSlide key={item.id} className="py-16 ">
                    <Card
                      shadow="none"
                      className=" mx-9 h-[350px]  p-0"
                      style={{
                        boxShadow: `
                          inset 0 4px 9px 1px #75757526, 
                          inset 0 -4px 9px 1px #75757526, 
                          inset 4px 0 9px 1px #75757526, 
                          inset -4px 0 9px 1px #75757526
                        `,
                      }}
                    >
                      <CardBody className="relative px-6  flex flex-col justify-between">
                        <div>
                          <RiDoubleQuotesL
                            size={50}
                            className="absolute top-4 left-4 text-[#d2d2d2]"
                          />
                          <div className="mt-11">
                            <p className="text-[#757575] font-sans leading-7 line-clamp-[8]">
                              {item.message}
                            </p>
                          </div>
                        </div>

                        <div className="flex justify-between items-center mt-4">
                          <div>
                            <h3 className="font-medium font-sans text-lg">
                              {item.name}
                            </h3>
                          </div>
                          <div className="w-10 h-10 rounded-full overflow-hidden">
                            <Image
                              src={item.image}
                              width={40}
                              height={40}
                              alt={`${item.name}'s profile picture`}
                              className="object-cover w-full h-full"
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                ))}

                {/* Add more SwiperSlides here */}
              </Swiper>
            </div>
          ) : (
            // for mobile
            <Swiper>
              {testimonials.map(item => (
                <SwiperSlide key={item.id}>
                  <Card className="flex flex-wrap items-center  mt-5 ">
                    <CardHeader className="px-3 py-2">
                      <p className="text-[#4F4F4F] font-sans text-xs">
                        {item.message}
                      </p>
                      <RiDoubleQuotesR
                        size={45}
                        className="absolute right-2 top-1/3 transform-translate-y-1/2  text-gray-300"
                      />
                    </CardHeader>
                    <CardBody className="px-3 py-2">
                      <div className="flex items-center gap-2  ">
                        <Image
                          src={item.image}
                          // fill
                          width={40}
                          height={40}
                          alt={`${item.name}'s profile picture`}
                          className="rounded-full border-2 bg-grap-300 h-full"
                        />
                        <div>
                          <h3 className="font-semibold font-sans text-sm">
                            {item.name}
                          </h3>
                          {/* <p className="font-sans text-sm">
                            {item.role}
                          </p> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          {/* Custom Pagination Element */}
          {/* <div className="custom-pagination flex justify-center space-x-2"></div> */}
        </div>
      </div>
    </BackgroundMask>
  );
};

export default OurCommunity;

import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import Link from 'next/link';
import HexagonCard from './HexagonCard';
import MobileHexagonCard from './MobileHexagonCard';
import { BackgroundMask } from '../commons/BackgroundMask';
import CTAButton from '../commons/CTAButton';

const HeroSection = () => {
  const [responsive, setResponsive] = useState('vertical');

  useEffect(() => {
    const handleResize = () => {
      const newOrientation =
        window.innerWidth >= 768 ? 'horizontal' : 'vertical';
      setResponsive(newOrientation);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <BackgroundMask>
        <div className=" lg:h-[91vh]  flex flex-col lg:flex-row items-center lg:justify-between layoutBox">
          <div className="items-center text-center font-sans">
            <div className="md:text-start space-y-2 ">
              <h1 className="lg:text-5xl text-black md:text-4xl text-2xl font-medium tracking-normal leading-relaxed">
                One Platform,
                <span className="bg-custom-gradient bg-clip-text text-transparent  block lg:py-1">
                  Endless Unique Opportunities!
                </span>
              </h1>
              <h3 className="lg:text-xl lg:py-2  tracking-normal">
                Step into a world of limitless opportunities! Learn from best,
                organize hackathons, showcase skills, explore courses, and
                connect with your dream company.
                {/* <br className="md:block hidden" />
                Unlock your potential today! */}
              </h3>

              {/* Web and Tablet Button */}
              <div className="md:block hidden">
                <CTAButton
                  as={Link}
                  href={'/organize-event'}
                  className="py-6 px-14"
                >
                  Organize Events
                </CTAButton>
              </div>

              {/* Mobile Button */}
              <div className="md:hidden">
                <Button
                  as={Link}
                  href={'/organize-event'}
                  variant="ghost"
                  size="sm"
                  className="text-xs bg-white border-1 border-[#097969] bg-[#097969] text-white hover:text-primary py-4"
                >
                  Organize Event
                </Button>
              </div>
            </div>
          </div>
          {responsive === 'horizontal' ? (
            <HexagonCard />
          ) : (
            <MobileHexagonCard />
          )}
        </div>
      </BackgroundMask>
    </>
  );
};

export default HeroSection;
